<template>
  <div class="container">
    <div :style="showBgColor?'width:100%':''">
      <div v-for="(item,index) in descList" :key="index" :class="{List:true,ListBgColor:showBgColor}" :style="{height:ListHeight}">
        <div style="height:100%" :class="showBgColor?'ListImg':''">
          <img :src="item.src" alt="" :style="{width:imgWidth,height:imgWidth}" >
        </div>
        <div class="ListRight" :style="showBgColor?'justify-content: center;':''">
          <div class="ListTitle" v-if="item.title">{{item.title}}</div>
          <div>
            <div class="ListP" v-for="(content,i) in item.desc" :key="i" :style="item.title?'':'line-height:24px'">{{content.p}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'moListDesc',
  props: {
    descList: {
      type: Array,
      default: () => {
        return null
      }
    },
    imgWidth: {
      type: String
    },
    ListHeight: {
      type: String
    },
    showBgColor: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped>
*{
  margin: 0;
  padding: 0;
}
.container{
  width: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
}
.List{
  width: 100%;
    display: flex;
    margin: .75rem 0;
    padding: .625rem 0;
    align-items: center;
    /* margin-top: 1.875rem; */
}
.ListBgColor{
    padding: .625rem;
background-color: #fff;
}
.ListRight{
    display: flex;
    flex-direction: column;

    margin-left: 1.25rem;
    /* margin-top: .3125rem;
    margin-bottom: .3125rem; */
}

.ListTitle{
font-size: 1rem;
font-weight: 550;
color: #222222;
margin-bottom: .625rem;
}
.ListP {
font-size: .75rem;
font-weight: 400;
color: #444444;
line-height: 1.25rem;
}
.ListImg{
display: flex;
align-items: center;
}
</style>
