<template>
  <div :class="{container:true,notScroll:isScroll}">
    <!-- 顶部 -->
    <MoTitle :showCenter="false" @moTitltFn="navPanel"></MoTitle>
    <div class="MoHomeContaint">
      <!-- 头部 -->
      <div class="moHomeTitle center">
        <el-carousel height="240px" style="width:100%" arrow="never">
          <el-carousel-item v-for="(item,index) in headBanner" :key="index">
            <img :src="item.src" alt="" class="moHeadBanImg">
            <div class="moHeadBannerContent">
              <div style="z-index:10;color:#fff;font-size: 20px;padding:0px 34px 20px;text-shadow: 0px 4px 16px rgba(0,0,0,0.2);line-height: 30px;font-weight: 550;text-align:center;box-sizing: border-box;">
                {{item.title}}
              </div>
              <div style="z-index:10">
                <button class="moInuptBtnTop" @click="$router.push(item.goPath)">了解更多</button>
                <button class="moLookVadio" @click="play = true">观看视频</button>
              </div>
            </div>
        </el-carousel-item>
        </el-carousel>
      </div>

       <!-- 智慧HR -->
      <div class="contentBgColor center">
        <h2 class="h2Desc">智慧HR--人力资源生命周期全覆盖</h2>
        <span class="spanDesc"></span>
        <div class="moHrNav">
          <div :class="{moHrNavCard:true,clickHr:hrIndex===index}" v-for="(item,index) in HrNav" :key="index" @click.stop="hrIndex=index">
            <img :src="item.src" alt="" style="width:2.5rem;margin-top:15px">
            <p style="font-size:0.75rem;margin-top:.625rem;text-align:center">{{item.title}}</p>
          </div>
        </div>
        <div class="moHrContent">
            <div class="moHrContentDesc">
              <div class="hrDescTitle">{{HrIndex[hrIndex].left.title}}</div>
              <span></span>
              <p>{{HrIndex[hrIndex].left.p}}</p>
              <button class="freeMinBtn" style="margin-right:1.25rem" @click="$router.push('/moShow')">免费试用</button>
              <button class="moreBtn" @click="$router.push(HrIndex[hrIndex].left.path)">了解更多</button>
              <img :src="HrIndex[hrIndex].right.src" alt="">
            </div>
        </div>
      </div>

      <!-- 核心业务专注 -->
      <div class="contentBgColor center">
        <h2 class="h2Desc">高校人力资源领域深耕细作</h2>
        <p class="pdesc">专业源于专注 专注产生价值</p>
        <span class="spanDesc"></span>
        <div style="margin:10px 0 30px;width:100%">
          <MoListDesc :descList="coreDesc" imgWidth="80px"></MoListDesc>
        </div>
      </div>

      <!-- 400+高校的最优选择 -->
      <div class="moSchoolContent">
        <h2 class="h2Desc">400<span class="jiaTop" style="font-size:20px;margin-right:5px">+</span>高校的最优选择</h2>
        <span class="spanDesc"></span>
        <div class="moSchoolValueCon">
          <div class="moSchoolBtnCon" ref="wisdomList">
            <div v-for="(item,index) in schoolList" :key="index" style="flex-basis: 50%;padding: 10px;box-sizing: border-box"  @click="changeItem(index)">
              <div :class="{moSchoolBtnItem:true,schoolBtnItemActive:index===wisdomShow}">
                <span :class="{moSchoolText:true,moSchoolTextActive:index===wisdomShow}">
                  {{item.btnTitleOne}}
                  <span class="jiaschoolTop" v-if="item.jia">{{item.jia}}</span>
                  </span>
                <span :class="{moSchoolText:true,moSchoolTextActive:index===wisdomShow}">
                  {{item.btnTitleTwo}}
                  <span class="jiaschoolTop" v-if="item.jia2">{{item.jia2}}</span>
                </span>
                <img src="../assets/home/wave.png"
                    :class="{
                    wisdomWave: true,
                    wisdomWaveindex: index===wisdomShow,
                  }"/>
              </div>
            </div>
          </div>
          <div style="width:100%;margin-bottom:30px">
            <div v-for="(item,index) in schoolList" :key="index" >
              <img :src="item.img" alt="" v-if="index===wisdomShow" style="width:100%;margin-top:20px">
              <div v-if="index===wisdomShow" style="text-align:right;font-size:12px;color:#999;width:100%;margin:auto;padding-right:20px">排名不分先后</div>
            </div>
          </div>
        </div>
        <!-- <button class="moreBtn" style="margin:0px 0 30px" @click="$router.push('/moCooperation/moCustomer')">了解更多</button> -->
      </div>

      <div class="fullPageVideo" v-if="play">
        <div class="videoWrap">
          <span class="el-icon-error closePlay" @click="play = false"></span>
          <video width="100%" autoplay loop controls preload="auto" webkit-playsinline="true" playsinline="true">
            <source src="@/assets/about/vidio.mp4" type="">
          </video>
        </div>
      </div>
      <MoBottom></MoBottom>
      <MoFoot></MoFoot>
    </div>
  </div>
</template>

<script>
import MoTitle from '@/components/Motitle'
import MoListDesc from '@/components/moListDesc'
export default {
  name: 'MoHome',
  data () {
    return {
      hrIndex: 0,
      isScroll: false,
      headBanner: [
        {
          src: require('@/assets/home/2023Banner.png'),
          title: '高校人力资源数字化解决方案',
          goPath: 'moSlution/moSchool'
        },
        {
          src: require('@/assets/home/bannerTwo.png'),
          title: '聚焦智慧人事，共创数智校园',
          goPath: 'moCooperation/moTypicalcase'
        },
        {
          src: require('@/assets/home/bannerThree.png'),
          title: '同行致远，未来可期',
          goPath: 'moAbout'
        }
      ],
      coreDesc: [
        {
          src: require('../assets/home/coreStudy.png'),
          title: '业务专注专业',
          desc: [
            {
              p: '专注于高校人力资源信息化领域，系统完全根据高校的治理模式和业务特点进行设计和开发，产品迭代升级到V9版本，以数据和服务为核心，服务驱动，数据赋能。'
            }
          ]
        },
        {
          src: require('@/assets/home/cpped.png'),
          title: '产品匹配度高',
          desc: [
            {
              p: '根据高校人力资源管理与服务的特点进行产品的设计和开发，既能体现高校人力资源管理的普遍性要求，又能根据不同高校的个性化业务进行快速配置，满足不同高校的具体业务需求。'
            }
          ]
        },
        {
          src: require('../assets/home/coreBus.png'),
          title: '实施经验丰富',
          desc: [
            {
              p: '400多所高校人力资源信息化项目的实施，积累了丰富的实施经验，能够最大程度地保障项目的成功实施。'
            }
          ]
        },
        {
          src: require('../assets/home/coreServe.png'),
          title: '业务理解深刻',
          desc: [
            {
              p: '与中国高等教育学会以及多个高校的业务管理部门和专家进行高校人力资源相关的课题合作，保证团队对高校业务的深刻理解，这是做好“高校人力资源业务伙伴”的前提。'
            }
          ]
        },
        {
          src: require('../assets/home/jspt.png'),
          title: '最佳业务实践',
          desc: [
            {
              p: '整理不同高校用户的最佳业务实践，为新的用户提供借鉴和参考，在实施过程中为客户提供顾问式的服务，需求响应快，交付快。'
            }
          ]
        },
        {
          src: require('../assets/home/ptnl.png'),
          title: '平台能力强大',
          desc: [
            {
              p: '高校的人力资源具有天生的发展性，业务需求不断变化。易普行《高校人力资源管理与服务平台》能够随着高校业务的发展而进行功能扩展，支持高校不断发展的业务需求。'
            }
          ]
        }
      ],
      HrNav: [
        {
          src: require('../assets/home/recruitmentManage.png'),
          title: '招聘管理系统'
        },
        { src: require('../assets/home/inReport.png'), title: '基础人事系统' },
        { src: require('../assets/home/szpy.png'), title: '师资培养与发展系统' },
        { src: require('../assets/home/gp.png'), title: '岗位评聘系统' },
        {
          src: require('../assets/home/performance.png'),
          title: '考核与评估系统'
        },
        { src: require('../assets/home/payment .png'), title: '薪酬福利系统' }
      ],
      HrIndex: [
        {
          left: {
            title: '招聘管理系统',
            p: '将学校招聘工作实现网络化管理，对招聘计划、职位发布、简历投递、简历筛选、招聘评审、笔试、面试等实现全过程的流程化管理，可以实现移动招聘，为管理者及应聘者提供便捷的服务。',
            path: '/moProduct/moEmployment'
          },
          right: { src: require('@/assets/home/zhaoping.png') }
        },
        {
          left: {
            title: '基础人事系统',
            p: '以用户组织岗位体系、教职工信息库为基础，实现不同类别人员入转调离全生命周期基础人事业务管理，推动人事管理理念更新、模式变革、流程优化，构建用户智慧HR管理体系。',
            path: '/moProduct/moBasePeople'
          },
          right: { src: require('@/assets/product/basePeople/personalIndex.png') }
        },
        {
          left: {
            title: '师资培养与发展系统',
            p: '师资培养与发展系统是对教师培训、国内进修、国外进修、挂职锻炼、外聘专家、教师资格考试、学术兼职、荣誉称号等业务的综合管理，助力师资队伍建设，促进教师职业发展。',
            path: '/moProduct/moProductSzpyyfzxt'
          },
          right: { src: require('@/assets/product/ProductSzpyyfzxt/gaint.png') }
        },
        {
          left: {
            title: '岗位评聘系统',
            p: '岗位评聘系统是对岗位聘任、职称评审、聘期考核业务的综合管理，基于教职工绩效数据，可实现申报信息自动抽取、评审材料电子化、评审过程网络化及流程化、评聘过程全留痕。',
            path: '/moProduct/moPosition'
          },
          right: { src: require('@/assets/product/position/gaint.png') }
        },
        {
          left: {
            title: '考核与评估系统',
            p: '考核与评估系统是针对教职工分类评价业务的管理。支持定性考核与定量考核等多种考核模式，通过对团体或个人的绩效进行全过程、全方位管理，有效提升单位的组织管理能力，充分调动教师工作积极性，为绩效工资核定发放提供标准。',
            path: '/moProduct/moProductKhypg'
          },
          right: { src: require('@/assets/home/jiXiaoSystem.png') }
        },
        {
          left: {
            title: '薪酬福利系统',
            p: '在全面薪酬管理体系的指导下，基于人事处薪酬福利业务，以激励为目标导向，对教职工基本工资、绩效工资、社保、公积金、福利发放、合并发放计税等业务进行综合管理。',
            path: '/moProduct/moPaySystem'
          },
          right: { src: require('@/assets/home/wageSystem.png') }
        }

      ],
      schoolList: [
        {
          img: require('@/assets/Mobile/syl.png'),
          btnTitleOne: '双一流院校70',
          jia: '+',
          btnTitleTwo: '市场覆盖率50%',
          jia2: '+'
        },
        {
          img: require('@/assets/Mobile/bk.png'),
          btnTitleOne: '本科院校200',
          jia: '+'
          // btnTitleTwo: '市场覆盖率28%'
        },
        {
          img: require('@/assets/Mobile/zyyx.png'),
          btnTitleOne: '职业院校100',
          jia: '+'
          // btnTitleTwo: '市场覆盖率16%'
        },
        {
          img: require('@/assets/MoHome/kys.png'),
          btnTitleOne: '科研院所',
          btnTitleTwo: '医院50',
          jia2: '+'
        }
      ],
      wisdomShow: 0,
      play: false
    }
  },
  components: {
    MoTitle,
    MoListDesc

  },
  created () {
    // 监听可视窗口宽度
    window.addEventListener('resize', this.getHeight)
    this.getHeight()
  },
  destroyed () {
    window.removeEventListener('resize', this.getHeight)
  },
  methods: {
    // 获取是否打开弹窗的值
    navPanel (v) {
      this.isScroll = v
    },
    // 通过监听可视窗口宽度转跳页面
    getHeight () {
      if (window.innerWidth > 800) {
        this.$router.push('/home')
      }
    },
    changeItem (value) {
      const lis = Array.from(this.$refs.wisdomList.children)

      this.wisdomShow = value
      lis.forEach((item) => {
        const btn = item.children[0]
        btn.style.bottom = '-24px'
        btn.style.right = '0px'
      })

      const curBtn = lis[value].children[0]
      curBtn.style.bottom = 0
      curBtn.style.right = '-300px'
    }
  }

}
</script>

<style scoped lang='scss'>
* {
  margin: 0;
  padding: 0;
  font-size: 16px;
  // color: #222;

}
img {
   object-fit: cover;
}
.container{
  width: 100%;
}
.notScroll{
  height: 0rem;
  overflow: hidden;
}
.center {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.freeMinBtn{
  width: 6rem;
  height: 2rem;
  background: linear-gradient(135deg, #FF8484 0%, #C61F1F 100%);
  border-radius: 1.875rem;
  border: 0;
  line-height: 2rem;
  font-size: .875rem;
  color: #fff;
}
.moreBtn{
  width: 6rem;
  height: 2rem;
  border-radius: 1.875rem;
  border: 1px solid rgba(211, 218, 228, 0.5);
  line-height: 2rem;
  color: #444444;
  font-size: .875rem;
  background-color: #fff;
}
.h2Desc{
  font-size: 1.375rem;
  color: #222;
  margin-top: 2.5rem;
}
.spanDesc{
  display: inline-block;
  background-color: #D70C0C;
  width: 1.875rem;
  height: .25rem;
  border-radius: .1875rem;
  margin-top: 1.25rem;
}
.pdesc {
  font-size: 1rem;
  color: #222;
  line-height: 1.5rem;
  margin-top: .625rem;
}
.contentBgColor{
  width: 100%;
    padding: 0 1.25rem;
box-sizing: border-box;
  background: linear-gradient(180deg, #FFFFFF 0%, #F7F5F4 100%);
}

.MoHomeContaint{
  padding-top: 2.75rem;
}
.moHomeTitle{
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 240px;
}
.moHomeTitle{
  &::v-deep {
      .el-carousel__button {
      width: 8px;
      height: 8px;
      background: #d70c0c !important;
      border-radius: 5px;
    }
  }
}
.moHeadBanImg{
  width:100%;
  height:100%;
  position:absolute;
  top: 0;
  left: 0;
  object-fit: cover;
}
.moHeadBannerContent{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}

.moInuptBtnTop {
  z-index:10;
  width: 120px;
  height: 36px;
  background: linear-gradient(135deg, #ff8484 0%, #c61f1f 100%);
  border-radius: 34px;
  font-size: 14px;
  color: #fff;
  border: 0;
}
.moLookVadio{
  width: 120px;
  height: 36px;
  border-radius: 34px;
  border: 1px solid #FFFFFF;
  background-color: transparent;
  color: #fff;
  margin-left: 20px;
  font-size: 14px;
}

.moHrNav{
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 1.875rem;
}
.moHrNavCard{
  display: flex;
  flex-direction: column;
  align-items: center;
  // justify-content: center;
  width: 33%;
  height: 100px;
}
.clickHr{
  background: #FFFFFF;
box-shadow: 0px 4px 20px -4px rgba(0, 0, 0, 0.15);
border-radius: 10px;
}
.moHrContent{
  width: 100%;
  margin-top: 1.875rem;
}
.moHrContentDesc{
width: 100%;
}
.hrDescTitle{
font-size: 1rem;
font-weight: 550;
color: #222222;
text-align: left;
}
.moHrContentDesc span {
  display: inline-block;
  width: 1.25rem;
height: .1875rem;
background: #D70C0C;
margin: .625rem 0;
}
.moHrContentDesc img {
  width: 100%;
  margin: 1.875rem 0 2.5rem;
}
.moHrContentDesc p{
  font-size: .75rem;
  color: #444;
  line-height: 1.25rem;
  margin-bottom: 1.25rem;
}

.moSchoolContent {
  width: 100%;
  background: linear-gradient(180deg, #ffffff 0%, #f7f5f4 100%);
  display: flex;
  flex-direction: column;
  align-items: center;
}
.moSchoolValueCon{
  width: 100%;
  padding-top: 30px;
}
.moSchoolBtnCon{
  display: flex;
  flex-wrap: wrap;
  padding:0 10px;
}
.moSchoolBtnItem{
  height: 70px;
  position: relative;
  top: 0;
  left: 0;
  font-size: 17px;
  border-radius: 10px;
  box-shadow: 0px 4px 20px -4px rgba(0, 0, 0, 0.15);
  border: 0;
  overflow: hidden;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.moSchoolText{
  font-size: 14px;
  font-weight: 400;
  color: #444444;
}

.wisdomWave {
  display: block;
  position: absolute;
  width: 600px;
  height: 20px;
  right: 0px;
  bottom: -24px;
  transition: right 1.8s, bottom 1.8s;
}
.schoolBtnItemActive{
  background: linear-gradient(135deg, #ff8484 0%, #c61f1f 100%);
  box-shadow: 0px 8px 32px -6px rgba(131, 7, 7, 0.4);
}
.moSchoolTextActive{
  color: #fff;
}
.wisdomWaveindex {
  right: -200px;
  bottom:0px
  }

.fullPageVideo{
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0,0,0,0.2);
  z-index: 9999;
}
.closePlay{
  position: absolute;
  top: -30px;
  right:-10px;
  font-size: 25px;
  color: rgba(255, 255, 255,0.5);
  cursor: pointer;
}
.videoWrap{
  position: fixed;
  width: 90%;
  left: 50%;
  top: 40%;
  transform: translate(-50%,-50%);
  -webkit-transform: translate(-50%,-50%);
}
.jiaTop{
  position: relative;
  top: -10px;
  left: 0;
}
.jiaschoolTop{
  position: relative;
 top: -6px;
  left: -3px;
}
</style>
